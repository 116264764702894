require('../css/wag.scss');
require('@fortawesome/fontawesome-free/scss/fontawesome.scss');
require('@fortawesome/fontawesome-free/scss/solid.scss');
require('@fortawesome/fontawesome-free/scss/brands.scss');

window.$ = $;

$(function () {
    const $doc = $(document)
    const $window = $(window)
    const $header = $(".header")
    const $footer = $(".footer");

    const headerPlays = !$header.hasClass("alt");
    const footerPlays = !$footer.hasClass("alt");

    function setheader() {
        if (headerPlays) $header.toggleClass("alt", ($doc.scrollTop() > window.innerHeight * .7));
        if (footerPlays) $footer.toggleClass("alt", ($doc.scrollTop() > $footer.height()));
    }

    const $menuItems = $(".header a");
    const $menuToggle = $(".header i.fa-bars");

    $menuToggle.click(function () { $menuItems.toggleClass("show") });

    setheader();

    if (headerPlays || footerPlays) {
        $window.resize(setheader);
        $window.scroll(setheader);
    }

})